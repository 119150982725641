import { NodeProps } from "reactflow";
import { ProcessorCard } from "../../Cards/ProcessorCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

export const ProcessorListNode: React.FC<NodeProps<V2NodeData>> = ({
  data,
}) => {
  const { attributes } = data;
  const { setHoveredSet } = useV2PipelineGraph();

  return (
    <RoutingNodeWrapper
      componentType={"processors"}
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        <ProcessorCard processorCount={0} />
      </div>
    </RoutingNodeWrapper>
  );
};
