import { GetConfigurationWithRoutesQuery } from "../../graphql/generated";

export type V2Config = GetConfigurationWithRoutesQuery["configuration"];

export enum AttributeName {
  // Nodes and Edges
  ActiveTypeFlags = "activeTypeFlags",
  SupportedTypeFlags = "supportedTypeFlags",

  // Nodes Only
  ComponentPath = "componentPath",
  DestinationIndex = "destinationIndex",
  SourceIndex = "sourceIndex",
  ProcessorIndex = "processorIndex",
  Recommendations = "recommendations",
  ResourceID = "resourceId",
}

type V2NodeAttributes = Partial<Record<AttributeName, any>>;
export interface V2NodeData {
  attributes: V2NodeAttributes;
  connectedNodesAndEdges: string[];
}
