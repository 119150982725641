import { Fade, IconButton } from "@mui/material";
import { useMemo, useState } from "react";
import { ComponentType } from "../../../utils/classes/types";
import { NetworkIcon } from "../../Icons";
import { useRouting } from "./RoutingContext";
import styles from "./routing-node-wrapper.module.scss";

interface RoudeNodeWrapperProps {
  componentType: ComponentType;
  componentPath: string;
}

export const RoutingNodeWrapper: React.FC<RoudeNodeWrapperProps> = ({
  children,
  componentType,
  componentPath,
}) => {
  const [hovered, setHovered] = useState(false);

  const {
    connectableComponentPaths,
    onConnect: connectComponentRoute,
    onRouteMenuOpen,
    readOnly,
  } = useRouting();

  const connectable = useMemo(
    () => componentPath && connectableComponentPaths.includes(componentPath),
    [componentPath, connectableComponentPaths],
  );

  const classNames = [styles.container];
  if (connectable) {
    classNames.push(styles.highlighted);
  }
  function handleConnectClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!connectable) {
      return;
    }
    connectComponentRoute(componentPath!);
  }

  const isRoutable =
    componentType === "sources" || componentType === "processors";

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleConnectClick}
      className={styles.container}
    >
      <div className={connectable ? styles.highlighted : undefined}>
        {children}
      </div>
      {isRoutable && (
        <Fade in={hovered && !readOnly}>
          <IconButton
            classes={{ root: styles.btn }}
            onClick={(e) => onRouteMenuOpen(e, componentType, componentPath)}
          >
            <NetworkIcon width="15px" height="15px" />
          </IconButton>
        </Fade>
      )}
    </div>
  );
};
